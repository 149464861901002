import { Routes } from '@angular/router';
import { AuthGuard } from '@core/guards/auth.guard';

export const routes: Routes = [

/** Main router-outlet */

    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login'
    },
    {
        path: 'login',
        pathMatch: 'full',
        canActivate:[AuthGuard],
        loadComponent: () => import('./components/login/login.component').then(c => c.LoginComponent)
    },
    {
        path: 'overview',
        canActivate: [AuthGuard],
        loadComponent: () => import('./components/overview/overview.component').then(c => c.OverviewComponent),
        children: [
            {
                path: '',  // Empty path to handle named outlet
                outlet: 'mypages',  // Define the outlet for named routes
                children: [
                    {
                        path: 'mina-arenden',
                        loadComponent: () => import('./components/incident/incident-list/incident-list.component').then(c => c.IncidentListComponent),
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'mina-ordrar',
                        loadComponent: () => import('./components/order/order-list/order-list.component').then(c => c.OrderListComponent),
                        canActivate: [AuthGuard],
                    },
                    // {
                    //   path: 'mina-ordrar/:id',
                    //   loadComponent: () => import('./components/order/order-detail/order-detail.component').then(c => c.OrderDetailComponent),
                    //   canActivate: [AuthGuard],
                    // },
                    {
                        path: 'mina-uppgifter',
                        loadComponent: () => import('./components/profile/profile.component').then(c => c.ProfileComponent),
                        canActivate: [AuthGuard],
                    },
                    {
                      path: 'mina-arenden/skapa',  // NOTE! This one must be define before 'mina-arenden/skapa'
                      loadComponent: () => import('./components/incident/incident-create/incident-create.component').then(c => c.IncidentCreateComponent),
                      canActivate: [AuthGuard],
                    },
                    {
                      path: 'mina-arenden/:id',  // Flattened structure for the :id parameter
                      loadComponent: () => import('./components/incident/incident-detail/incident-detail.component').then(c => c.IncidentDetailComponent),
                      canActivate: [AuthGuard],
                    }
                ]
            }
        ]
    },
    { path: '**',
      loadComponent: () => import('./components/notfound-404/notfound-404.component').then(c => c.Notfound404Component),
    }

];
