import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

    auth0 = inject(AuthService)
    router = inject(Router)

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        // Use Auth0's isAuthenticated$ observable to check if the user is authenticated
        return this.auth0.isAuthenticated$.pipe(
            map(isAuthenticated => {
                if (isAuthenticated && state.url == '/login') {
                    // If the user is authenticated, redirect them away from the /login page
                    this.router.navigate(['/overview']);
                } else if(isAuthenticated) {
                    return true
                } else if(!isAuthenticated && state.url == '/login') {
                    return true
                }

                return false
            }),
            
        )
    }
}