import { inject, Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

// documentation: https://www.npmjs.com/package/ngx-toastr#use

@Injectable({
  providedIn: 'root',
})
export class NgxToastrService {
  #toastr = inject(ToastrService);

  showSuccess(title: string, message?: string, options?: any) {
    this.#toastr.success(
      message,
      title,
      options ?? {
        timeOut: 5000,
        disableTimeOut: false,
        progressBar: true,
        positionClass: 'toast-top-left',
      }
    );
  }

  showError(title: string, message?: string, options?: any) {
    this.#toastr.error(
      message,
      title,
      options ?? {
        disableTimeOut: true,
        closeButton: true,
        progressBar: false,
        tapToDismiss: true,
        positionClass: 'toast-bottom-full-width',
      }
    );
  }

  showWarning(title: string, message?: string, options?: any) {
    this.#toastr.warning(
      message,
      title,
      options ?? {
        closeButton: true,
        tapToDismiss: true,
        disableTimeOut: true,
        positionClass: 'toast-bottom-left',
      }
    );
  }

  showInfo(title: string, message?: string, options?: any) {
    this.#toastr.info(
      message,
      title,
      options ?? {
        timeOut: 10000,
        closeButton: true,
        tapToDismiss: true,
        positionClass: 'toast-top-right',
        progressBar: false,
      }
    );
  }

  clearAll(id?: number) {
    this.#toastr.clear(id)
  }
}
