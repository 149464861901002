import { DestroyRef, ErrorHandler, inject, Injectable } from '@angular/core';
import { Notifier } from '@airbrake/browser';
import { environment } from 'environments/environment';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

const env = environment.title
const airbrakeProject = environment.airbrake

@Injectable()
export class AirbrakeErrorHandler implements ErrorHandler {
  #airbrake: Notifier
  #routeMetric: any
  #destroyRef = inject(DestroyRef)
  #router = inject(Router)

  constructor() {
    this.#airbrake = new Notifier({
      projectId: airbrakeProject.id,
      projectKey: airbrakeProject.key,
      environment: env
    });

    if(env == 'prd') {
      this.#router.events.pipe(
        takeUntilDestroyed(this.#destroyRef)
      ).subscribe((event) => {
        if (event instanceof NavigationStart) {
          // Start the route metric when navigation starts
          this.#routeMetric = this.#airbrake.routes.start('GET', event.url, 200, 'application/json');
        } else if (event instanceof NavigationEnd || event instanceof NavigationError) {
          // Notify Airbrake of route completion on navigation end or error
          if (this.#routeMetric) {
            this.#routeMetric.statusCode = event instanceof NavigationEnd ? 200 : 500;
            this.#airbrake.routes.notify(this.#routeMetric);
          }
        }
      });
    }
  }

  handleError(error: any): void {
    console.error(error);  // Keeps the error log visible in the console

    this.#airbrake.notify({
      error: error,
      context: {
        severity: 'error',
        userAgent: navigator.userAgent, // for browser information
        url: window.location.href       // for current URL tracking
      }
    });
  }
}

