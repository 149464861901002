import { inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NgxToastrService } from './ngx-toastr.service';
import { UiError } from '@models/error/error.model';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  #toastrService = inject(NgxToastrService);

  private errorSubject = new Subject<any>();
  error$ = this.errorSubject.asObservable();

  notifyError(error: any, uiError: UiError) {

    console.error(error)
    // notify all observers
    this.errorSubject.next(uiError);

    this.#toastrService.showError(uiError.title ?? `Error - ${error.status}`, uiError.message);
  }
}
