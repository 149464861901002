import { ApplicationConfig, ErrorHandler, LOCALE_ID } from '@angular/core';
import { provideRouter, withViewTransitions } from '@angular/router';
import { routes } from './app.routes';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthHttpInterceptor, provideAuth0 } from '@auth0/auth0-angular';
import { environment } from '../environments/environment';
import localeSv from '@angular/common/locales/sv';
import { registerLocaleData } from '@angular/common';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideToastr } from 'ngx-toastr';
import { ErrorHandlerInterceptor } from '@core/interceptors/error-handler.interceptor';
import { AirbrakeErrorHandler } from '@utils/error-handling/airbrake-error-handler';

registerLocaleData(localeSv, 'sv-SE');

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(routes, withViewTransitions()),
    provideAnimations(),
    provideToastr({
      preventDuplicates: true,
      enableHtml: true,
      tapToDismiss: true
    }),
    provideAuth0({
      domain: environment.auth0Domain,
      clientId: environment.auth0ClientId,
      cacheLocation: 'localstorage', // Optional: Store token in localStorage
      useRefreshTokens: true,        // Optional: Enable silent token renewal

      authorizationParams: {
        redirect_uri: environment.auth0Callback,
        scope: 'openid profile email offline_access',
        audience: environment.auth0Audience
      },

      httpInterceptor: {
        allowedList: [
          {
            // Attach token for any request to this API URL
            uri: `${environment.apiBaseUrl}/*`, // MyPages backend api
          }
        ]
      }
    }),
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true},
    { provide: ErrorHandler, useClass: AirbrakeErrorHandler},
    { provide: LOCALE_ID, useValue: 'sv-SE' }
  ]
};


