import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, EMPTY, Observable, throwError } from 'rxjs';
import { ErrorService } from '@services/error.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerInterceptor implements HttpInterceptor {

  #errorService = inject(ErrorService)

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {

        let errorTitle: string = '';
        let errorMessage: string = '';

        if (error?.error instanceof ErrorEvent) {
          // Client-side error
          errorTitle = 'Ett fel uppstod';
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // Server-side error
          switch (error.status) {
            case 400:
            case 401:
            case 404:
            case 500:
            case 502:
              errorTitle = `Error - ${error.status}`
              break;

            case 0:
            default:
              errorTitle = 'Okänt fel'
              break;
          }
        }

        this.#errorService.notifyError(error, {title: errorTitle, message: `Kontakta kundtjänst om felet kvarstår (${error?.name}).`})

        return EMPTY; // returning EMPTY prevents the error to propagate further (avoiding airbrake to catch the error and log it)
      })
    );
  }
}
